// (c) Copyright 2021 Justin Lloyd. All rights reserved.


// difficulty 0
export const DIFFICULTY_0_INVADER_COUNT = 2;
export const DIFFICULTY_0_INVADER_SPAWN_INTERVAL = 1600;
export const DIFFICULTY_0_INVADER_SPAWN_CHANCE = 0.1;
export const DIFFICULTY_0_INVADER_LOWEST_STARTING_ROW = 2;
export const DIFFICULTY_0_INVADER_FIRING_DELAY = 2000;
export const DIFFICULTY_0_INVADER_MOVEMENT_DELAY = 2000;
export const DIFFICULTY_0_INVADER_FIRING_CHANCE = 0.005;
export const DIFFICULTY_0_INVADER_STEP_DOWN_CHANCE = 0.005;
export const DIFFICULTY_0_INVADER_STEP_SIDEWAYS_CHANCE = 0.01;

export const DIFFICULTY_0_DEATH_RAY_COUNT = 2;
export const DIFFICULTY_0_DEATH_RAY_MOVEMENT_DELAY = 400;

export const DIFFICULTY_0_BONUS_CHANCE = 0.0075;
export const DIFFICULTY_0_BONUS_MOVEMENT_DELAY = 1000;
export const DIFFICULTY_0_BONUS_POINT_VALUE = 10;

export const DIFFICULTY_0_MISSILE_BASE_MOVEMENT_DELAY = 350;
export const DIFFICULTY_0_MISSILE_BASE_FIRING_DELAY = 400;
export const DIFFICULTY_0_MISSILE_BASE_MISSILE_COUNT = 2;

export const DIFFICULTY_0_MISSILE_MOVEMENT_DELAY = 200;
export const DIFFICULTY_0_MISSILE_COUNT = 2;

// difficulty 1
export const DIFFICULTY_1_INVADER_COUNT = 2;
export const DIFFICULTY_1_INVADER_SPAWN_INTERVAL = 1300;
export const DIFFICULTY_1_INVADER_SPAWN_CHANCE = 0.15;
export const DIFFICULTY_1_INVADER_LOWEST_STARTING_ROW = 2;
export const DIFFICULTY_1_INVADER_FIRING_DELAY = 1500;
export const DIFFICULTY_1_INVADER_MOVEMENT_DELAY = 1500;
export const DIFFICULTY_1_INVADER_FIRING_CHANCE = 0.075;
export const DIFFICULTY_1_INVADER_STEP_DOWN_CHANCE = 0.03;
export const DIFFICULTY_1_INVADER_STEP_SIDEWAYS_CHANCE = 0.03;

export const DIFFICULTY_1_DEATH_RAY_COUNT = 2;
export const DIFFICULTY_1_DEATH_RAY_MOVEMENT_DELAY = 350;

export const DIFFICULTY_1_BONUS_CHANCE = 0.0075;
export const DIFFICULTY_1_BONUS_MOVEMENT_DELAY = 800;
export const DIFFICULTY_1_BONUS_POINT_VALUE = 10;

export const DIFFICULTY_1_MISSILE_BASE_MOVEMENT_DELAY = 350;
export const DIFFICULTY_1_MISSILE_BASE_FIRING_DELAY = 400;
export const DIFFICULTY_1_MISSILE_BASE_MISSILE_COUNT = 2;

export const DIFFICULTY_1_MISSILE_MOVEMENT_DELAY = 200;
export const DIFFICULTY_1_MISSILE_COUNT = 2;

// difficulty 2
export const DIFFICULTY_2_INVADER_COUNT = 2;
export const DIFFICULTY_2_INVADER_SPAWN_INTERVAL = 1000;
export const DIFFICULTY_2_INVADER_SPAWN_CHANCE = 0.2;
export const DIFFICULTY_2_INVADER_LOWEST_STARTING_ROW = 3;
export const DIFFICULTY_2_INVADER_FIRING_DELAY = 1200;
export const DIFFICULTY_2_INVADER_MOVEMENT_DELAY = 1200;
export const DIFFICULTY_2_INVADER_FIRING_CHANCE = 0.1;
export const DIFFICULTY_2_INVADER_STEP_DOWN_CHANCE = 0.05;
export const DIFFICULTY_2_INVADER_STEP_SIDEWAYS_CHANCE = 0.05;

export const DIFFICULTY_2_DEATH_RAY_COUNT = 2;
export const DIFFICULTY_2_DEATH_RAY_MOVEMENT_DELAY = 300;

export const DIFFICULTY_2_BONUS_CHANCE = 0.0075;
export const DIFFICULTY_2_BONUS_MOVEMENT_DELAY = 600;
export const DIFFICULTY_2_BONUS_POINT_VALUE = 10;

export const DIFFICULTY_2_MISSILE_BASE_MOVEMENT_DELAY = 350;
export const DIFFICULTY_2_MISSILE_BASE_FIRING_DELAY = 400;
export const DIFFICULTY_2_MISSILE_BASE_MISSILE_COUNT = 2;

export const DIFFICULTY_2_MISSILE_MOVEMENT_DELAY = 200;
export const DIFFICULTY_2_MISSILE_COUNT = 2;

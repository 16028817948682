// (c) Copyright 2021 Justin Lloyd. All rights reserved.

import GameObject from "./GameObject";

export default class DebugInfo extends GameObject
{
    init()
    {
        // TODO send network event here
        // TODO make the network event that is dispatched look like it is enabling debug features on the server
    }

}
